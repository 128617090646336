import React, { Component, Fragment } from 'react'
import {
  Router,
  Route, 
  Switch, 
} from 'react-router-dom'

import './global.styles'

import Header from '../modules/header'
import history from './history'

import AsyncComponent from '../../AsyncComponent';

const AsyncHome = AsyncComponent(() => import('./home'))
const AsyncHobbyGallery = AsyncComponent(() => import('./hobbygallery'))
const AsyncNotFound = AsyncComponent(() => import('./notfound'))

const RoutesWithHeaderAndFooter = () => {
  return (
    <Fragment>
      <Header />
      <main>
        <Switch>
          <Route path='/hobby-gallery' render={props => <AsyncHobbyGallery {...props} />} />
          <Route path='*' render={props => <AsyncNotFound {...props} />} />
        </Switch>
      </main>
    </Fragment>
  )
}

class App extends Component {
  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/' render={props => <AsyncHome {...props} />} />        
          <Route
                path='*'
                render={props => RoutesWithHeaderAndFooter(props)}
              />
        </Switch>
      </Router>
    )
  }
}

export default App
