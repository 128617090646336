import { injectGlobal } from 'emotion'

// fonts
import CaviarFont from '../../assets/fonts/CAVIARDREAMS.WOFF'

import {
	colours,
	pxToRem,
	fontWeights,
} from '../../styles'

injectGlobal`
	@font-face {
		font-family: "Caviar";
		src: url(${CaviarFont}) format('woff');
	}

	body {
		margin: 0;
		padding: 0;
		font-family: "Caviar", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
			"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: ${colours.primary};
		background-color: ${colours.darkGrey};
	}

	h1, 
	ol, 
	ul {
		margin: 0;
		padding: 0;
	}
	
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: ${fontWeights.bold};
	}

	h1 {		
		font-size: ${pxToRem(48)};
	}

	h2 {		
		font-size: ${pxToRem(36)};
	}

	h3 {
		font-size: ${pxToRem(28)};
	}

	h4 {
		font-size: ${pxToRem(18)};
	}

	p {
		font-size: ${pxToRem(16)};
	}
`