import React, { memo, Fragment } from 'react'
import { slide as Menu } from 'react-burger-menu'

import {
	Wrap,
	LogoLink,
	MenuWrap,
	MenuWrapItem,
	MenuWrapItemLink,
	MobileMenuWrap,
	BurgerStyles,
} from './header.styles'

const NavigationItems = () => {
	return [
		<MenuWrapItemLink title="Home" href="/">Home</MenuWrapItemLink>,
		<MenuWrapItemLink title="My Hobby Gallery" href="/hobby-gallery">Hobby Gallery</MenuWrapItemLink>,
		<MenuWrapItemLink title="My Hobby Web Store" href="https://www.modellersworkshop.com.au">Modeller's Workshop Store</MenuWrapItemLink>
	]
}

const MobileMenu = () => {
	return (
		<MobileMenuWrap>
			<Menu styles={BurgerStyles} right>
				{ NavigationItems() }
			</Menu>
		</MobileMenuWrap>
	)
}

const Header = memo(() => {
	return (
		<Wrap>
			<LogoLink title="Home" href="/">darrenyung.com</LogoLink>
			<nav>
				<MenuWrap>
					{
						NavigationItems().map((item, idx) => {
							return (
								<MenuWrapItem key={`nav_${idx}`}>{item}</MenuWrapItem>
							)
						})
					}
				</MenuWrap>
				{MobileMenu()}
			</nav>
		</Wrap>
	)
})

export default Header