export const BASE_FONT_SIZE = 16;

export const siteTransitionDuration = '150ms';
export const siteTransitionSlowDuration = '.5s';
export const siteTransitionReallySlowDuration = '2s';

export const pxToRem = (pixelValue) => `${pixelValue / BASE_FONT_SIZE}rem`;
export const space = (spacing) => `${pxToRem(spacing * 8)}`;

export const fontWeights = {
	light: '400',
	normal: '500',
	semiBold: '600',
	bold: '700',
}

export const fontSize = {
	xl: pxToRem(48),
	lg: pxToRem(32),
	md: pxToRem(24),
	sm: pxToRem(20),
	xs: pxToRem(16),
	xxs: pxToRem(12),
}

export const shadows = {
	low: '0px 3px 5px rgba(0, 0, 0, .05)',
	mid: '0px 5px 10px rgba(0, 0, 0, .15)',
	high: '0px 10px 15px rgba(0, 0, 0, .1)',
}

export const colours = {
	primary: '#EBF0F2',
    secondary: '#7C9AA6',
    tertiary: '#5D7A8C',

    darkGrey: '#171F26',
    mediumDarkGrey: '#364959',

    white: '#FFFFFF', 
	black: '#000000',
	red: '#FF0000',
	lightRed: '#FFE6E6',
	yellow: '#FFC300',
}

export const deviceBreakpoints = {
	xs: 320,
	sm: 550,
	md: 768,
	lg: 1024,
	xl: 1440,
}

export const mediaQueries = {
	xs: `@media only screen and (min-width: ${deviceBreakpoints.xs}px)`,
	sm: `@media only screen and (min-width: ${deviceBreakpoints.sm}px)`,
	md: `@media only screen and (min-width: ${deviceBreakpoints.md}px)`,
	lg: `@media only screen and (min-width: ${deviceBreakpoints.lg}px)`,
	xl: `@media only screen and (min-width: ${deviceBreakpoints.xl}px)`,
}

export const width = {
	xs: 'width: 300px;',
	sm: 'width: 500px;',
	md: 'width: 700px;',
	lg: 'width: 1000px;',
	xl: 'width: 1100px;',
}

export const Padding = {
	defaultPadding: '20px',
	defaultPaddingXL: '36px'
}