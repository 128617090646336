import styled from '@emotion/styled'

import {
	Padding,
	pxToRem,
	colours,
	fontWeights,
	fontSize,
	siteTransitionDuration,
	mediaQueries,
} from '../../../styles'

export const Wrap = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 ${Padding.defaultPadding};
	height: ${pxToRem(70)};
	border-bottom: 1px dotted ${colours.primary};
`

export const LogoLink = styled.a`
	color: ${colours.primary};
	font-weight: ${fontWeights.bold};
	text-decoration: none;
	font-size: ${fontSize.md};	
`

export const MobileMenuWrap = styled.div`
	${mediaQueries.md} {
		display: none;
	}
`

export const MenuWrap = styled.ul`
	display: none;

	${mediaQueries.md} {
		display: flex;
		list-style-type: none;
		justify-content: flex-end;
	}
`

export const MenuWrapItem = styled.li`
	&:not(:last-of-type) {
		margin-right: 30px;
	}
`

export const MenuWrapItemLink = styled.a`
	color: ${colours.primary};
	text-decoration: none;
	transition-duration: ${siteTransitionDuration};
	transition-property: color, background-color, border-color;

	&:focus,
	&:active {
		outline: none;
	}

	&:hover {
		color: ${colours.secondary};
	}
`

export const BurgerStyles = {
	bmBurgerButton: {
	  position: 'fixed',
	  width: '36px',
	  height: '30px',
	  right: '22px',
	  top: '22px'
	},
	bmBurgerBars: {
	  background: `${colours.primary}`,
	  height: '5%',
	},
	bmBurgerBarsHover: {
	  background: `${colours.primary}`
	},
	bmCrossButton: {
	  height: '24px',
	  width: '24px'
	},
	bmCross: {
	  background: `${colours.primary}`
	},
	bmMenuWrap: {
	  position: 'fixed',
	  top: 0,
	  height: '100%',
	  width: '100%'
	},
	bmMenu: {
	  background: `${colours.mediumDarkGrey}`,
	  padding: '2.5em 1.5em 0',
	  fontSize: '1.15em'
	},
	bmMorphShape: {
	  fill: `${colours.primary}`
	},
	bmItemList: {
	  color: `${colours.primary}`,
	},
	bmItem: {
	  margin: `${pxToRem(15)} 0`
	},
	bmOverlay: {
	  background: 'rgba(0, 0, 0, 0.3)'
	}
  }